import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import AppRouterSetup from './router/AppRouterSetup';
import store from './store';
import 'normalize.css'
import './index.scss'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
<Provider store={store}>
    <AppRouterSetup/>
</Provider>
);
